import { store } from '../store'
import { datadogLogs } from '@datadog/browser-logs'
import { getEnvironment } from './awsUtils'

export const initLogger = () => {
  datadogLogs.init({
    clientToken: 'pubf1692edee7a0c0df7d71ec15fc34bf08',
    site: 'datadoghq.com',
    service: 'providers-webapp',
    env: getEnvironment(),
    version: process.env.REACT_APP_VERSION,
    forwardErrorsToLogs: true,
    sampleRate: 100
  })
}

export const logInfo = (message, context) => {
  log(message, context, 'info')
}

export const logWarn = (message, context) => {
  log(message, context, 'warn')
}

export const logError = (message, error) => {
  log(message, error, 'error')
}

export const logReduxActionError = (action, context) => {
  log(
    `Redux action: ${action.type}`,
    { ...(context || {}), actionType: action.type, actionPayload: action.payload },
    'error'
  )
}

export const log = (logMessage, data, level) => {
  const state = store.getState()

  const context = {
    ...(data || {}),
    env: getEnvironment(),
    webappLoadTime: state.appReducer.loadedAt,
    userInfo: {
      username: state.profileReducer.doctor?.username,
      doctorId: state.profileReducer.doctor?.id,
      email: state.profileReducer.doctor?.email
    }
  }

  if (getEnvironment() === 'production' || getEnvironment() === 'staging') {
    datadogLogs.logger[level](logMessage, context)
  }
  if (getEnvironment() !== 'production') {
    console[level](logMessage, context)
  }
}

export const getSessionId = () => datadogLogs.getInternalContext().session_id

export const circularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}
